@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

:root {
  --secondary-color: #1e6649;
  --primary-color: #c8c9bb;
  --primary-color-focus: #9b9b99;
  --tertiary-color: #3e5a72;
  --font-color: #3f3f3f;
  --link-color: #1a3044;
  --red: #8a3533;
  --blue: #6287dd;
  --yellow: #cbc75f;
  --green: #0ca900;

  --body-radius: 2vw;
}

@media (max-width: 500px) {
  :root {
    font-size: 9px;
  }
}

html,
body {
  background-color: var(--primary-color);
  color: var(--font-color);
  font-family: 'Outfit', system-ui, 'Helvetic Neue', 'Helvetica', 'Arial',
    'sans-serif';
}

body {
  margin: 0rem 2rem;
}

#body-container {
  display: flex;
  background-color: var(--secondary-color);
  border-radius: var(--body-radius);
  border-bottom-right-radius: 0;
  max-width: 45rem;
  margin: 5vh auto;
}

#left-container {
  flex: 1.1;
  display: flex;
  flex-direction: column;
}

#tagline {
  background-color: var(--primary-color);
  padding: 1rem;
  /* border-bottom-right-radius: 3.33rem; */
  border-bottom-right-radius: var(--body-radius);
  position: relative;
  font-size: .9rem;
  color: var(--font-color);
}

#tagline>a:hover {
  color: var(--font-color);
}

#bottom-left-curve {
  position: absolute;
  left: 0px;
  top: 100%;
  fill: var(--primary-color);
  width: var(--body-radius);
  height: var(--body-radius);
}

#top-right-curve {
  position: absolute;
  left: 100%;
  top: 0px;
  fill: var(--primary-color);
  width: var(--body-radius);
  height: var(--body-radius);
}

#menu {
  padding: min(1.5rem, 10vh) min(1.5rem, 5vw);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.menu-item {
  padding-bottom: 1rem;
  z-index: 1;
  color: var(--primary-color);
}

#right-container {
  flex: 4;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

#right-container > div {
  margin-bottom: 1rem;
}

.project-img {
  margin-bottom: 1rem;
  height: auto;
  max-width: 100%;
}

.project-img:hover,
.project-img:focus {
  cursor: zoom-in;
}

a {
  color: var(--link-color);
  text-decoration: none;
  z-index: 1;
}

a:hover {
  color: var(--blue);
}

.project-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.keywords {
  font-style: italic;
  font-size: 0.8rem;
}

.project-demo {
  color: var(--yellow);
  font-style: italic;
}

#skills-container {
  display: flex;
  flex-wrap: wrap;
}

.skills-subset {
  display: flex;
  flex-direction: column;
  border: var(--primary-color);
  border-style: solid;
  border-radius: var(--body-radius);
  border-width: 0.1rem;
  padding: 0.4rem 0.5rem;
  margin: 0.2rem;
  justify-content: center;
}

.skill-title {
  align-self: center;
  font-size: 0.6rem;
  font-weight: bold;
  padding-bottom: 0.2rem;
}

.skill-icons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.skill-icon {
  display: flex;
  flex-direction: column;
  font-size: 0.5rem;
  align-items: center;
  margin: 0rem 0.5rem;
}

.skill-icon>i {
  font-size: 1.5rem;
}

.links-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
  font-size: 1.8rem;
}

#right-container>.links-container {
  margin-bottom: 0rem;
}

.links-container>a {
  margin-left: 0.5rem;
}

#modal {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgba(0,0,0,0.8);
}

.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#close {
  align-self: flex-end;
  stroke: var(--primary-color);
  stroke-width: 1rem;
  width: 1vw;
  padding: 1rem;
}

#close:hover,
#close:focus {
  stroke: var(--primary-color-focus);
  cursor: pointer;
}

.modal-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  transform: translateY(-1vw) translateY(-2rem);
  width: auto;
  height: auto;
}

.arrow {
  stroke: var(--primary-color);
  stroke-width: 0.5rem;
  width: 2vw;
  padding: 2vw;
  height: 2vw;
}

.arrow:hover,
.arrow:focus {
  stroke: var(--primary-color-focus);
  cursor: pointer;
}

.modal-img {
  width: auto;
  height: auto;
  max-width: 85%;
  max-height: 85%;
}

